import React, { Component, useEffect, useState, useRef } from "react";
import { render } from "react-dom";
import ReactDOM from "react-dom";
import classnames from "classnames";
import Api from "../../../package.json";
import { useForm } from "react-hook-form";
import * as axios from "axios";
import { NavLink as Link } from "react-router-dom";
//import './header.css';
import facebook from "../../../src/assets/facebook.png";
import wechat from "../../../src/assets/wechat.svg";
import linked_ic from "../../../src/assets/linked-ic.png";
import twitter from "../../../src/assets/twitter.png";
import insta from "../../../src/assets/insta.png";
import footer_logo from "../../../src/assets/footer_gsl_logo.png";
import footerDownArrowBtn from "../../../src/assets/down-arrow-footer.png";
import footer_search_icon from "../../../src/assets/search-ic.png";
import footer_track_black from "../../../src/assets/cargo-tr-black.svg";
import footer_tar_black from "../../../src/assets/tare-weight-ic-black.svg";
import footer_schedule_black from "../../../src/assets/schedule-ic-black.svg";
import footer_subscribe_black from "../../../src/assets/submis-ic-black.png";
import footer_point_black from "../../../src/assets/point_black.png";
import footer_track_white from "../../../src/assets/cargo-tr-white.png";
import footer_tar_white from "../../../src/assets/tare-weight-ic-white.png";
import footer_schedule_white from "../../../src/assets/schedule-ic-white-new.png";
import footer_subscribe_white from "../../../src/assets/submis-ic-white-new.png";
import footer_point_white from "../../../src/assets/point-white.png";
import gsl_go_icon from "../../../src/assets/Service-Route.svg";
import quote from "../../../src/assets/quote.svg";
import CloseButton from "react-bootstrap/CloseButton";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import footerLang from "../lang/footer";
import footerDownArrowNew from "../../../src/assets/down-arrow-siv.svg";
import footerUpArrowNew from "../../../src/assets/up-arrow-siv.svg";
import HCaptcha from "@hcaptcha/react-hcaptcha";
const qs = require("qs");
var jwt = require("jsonwebtoken");
const Footer = () => {
  var langs = window.localStorage.getItem("lang");
  const [country_code, setCountryCode] = useState(window.$country_code);
  const [footerHoverIconIndex, setfooterHoverIconIndex] = useState(-1);
  const [popHide, setpopHide] = useState(true);
  const [footerPopBottom, setfooterPopBottom] = useState(-1);
  const [fixedBottomBar, setfixedBottomBar] = useState(false);
  const [SubmitButton, setSubmitButton] = useState(footerLang[langs].GO);
  const [lang, setLang] = useState('');
  const [captcha, setHeadercaptcha] = useState([]);
  const headers = {
    type: "application/json",
    Authorization: `Bearer ` + window.localStorage.getItem('access_token'),
  };
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);
  const checkcontainer = async (val) => {

    const response = await axios.post(
      Api.userdata.url + "/container_bl_validation",
      qs.stringify({ containerid: val }), { headers: headers }
    );
    const res = await response.data;
    if (res == false) {
      return "Enter container number - format : 4 letters followed by 7 digits";
    } else {
      return true;
    }
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    getValues,
  } = useForm();
  const buttonRef = useRef();
  const history = useHistory();
  const onSubmit = (data) => {
    buttonRef.current.disabled = true;
    setSubmitButton("submitting...");
    save_newsletter(data);
  };
  const onSubmit_container = (data) => {
    console.log(data);
    var containerid = getValues('containerid');
    if (containerid != "") {
      history.push("/track_shipment/" + data.containerid);
    }
  };
  const save_newsletter = async (data) => {
    if(token != null)
    {
    const response = await axios.post(
      Api.userdata.url + "/save_news_letter",
      qs.stringify({
        country_code: country_code,
        email: data.email,
        token:token
      }), { headers: headers }
    );
    const json = await response.data;
    if (json["status"] == "OK") {
      swal("Successful Submitted", json["message"], "success").then((value) => {
        window.location.reload(false);
      });
    } else {
      swal("Error !", json["message"], "error");
    }
    buttonRef.current.disabled = false;
    setSubmitButton(footerLang[langs].GO);
  }
  else{
    buttonRef.current.disabled = false;
    setSubmitButton(footerLang[langs].GO);
    swal("Error !", "Invalid captcha", "error");
  }
  };
  const showFixedBar = (data) => {
    setfooterHoverIconIndex(-1);
    setfooterPopBottom(-1);
    setpopHide(true);
    var bt_flag = (window.localStorage.getItem('fixedBottomBar') == 'true') ? 'false' : 'true';
    window.localStorage.setItem('fixedBottomBar', bt_flag);
    setfixedBottomBar(window.localStorage.getItem('fixedBottomBar') == 'true' ? true : false);
    setfooterHoverIconIndex(-1);
  };

  useEffect(() => {
    if (window.localStorage.getItem('fixedBottomBar') == null) {
      window.localStorage.setItem('fixedBottomBar', 'true');
    }
    setfixedBottomBar(window.localStorage.getItem('fixedBottomBar') == 'true' ? true : false);
    if (window.localStorage.getItem("lang") == null) {
      setLang('en');
    }
  })
  return (
    <footer class="footer-section">
      <script src="https://cn1.hcaptcha.com/1/api.js?mobile.my.com" async defer></script>
      <div class="row1">
        <div class="col-xl-12 text-center">
          <div class="d-inline-block logo_img_center">
            {" "}
            <img class="logo_img_center1" src={footer_logo} />
          </div>
        </div>
      </div>
      <div class="footer-contain container">
        <div class="row m-0">
          <div class="col-xl-2 col-lg-2 col-sm-12 col-md-4 ">
            <ul class="footer-list-quk">
              <h4>
                <a href="javascript:void(0)"> {footerLang[langs].Service}</a>
              </h4>
              <li>
                <Link to="/point_to_point" className="drop-inner">
                  {footerLang[langs].PointToPoint}
                </Link>
              </li>

              <li>
                <Link to="/schedule_by_line" className="drop-inner">
                  {footerLang[langs].Schedule}
                </Link>
              </li>
              <li>
                <Link to="/cargo_catalogue" className="drop-inner">
                  {footerLang[langs].Equipment}
                </Link>
              </li>
              <li>
                <Link to="/cargo_services" className="drop-inner">
                  {footerLang[langs].CargoServices}
                </Link>
              </li>
            </ul>
          </div>
          <div class="col-xl-2 col-lg-2 col-sm-12 col-md-4 ">
            <ul class="footer-list-quk">
              <h4>
                <a href="javascript:void(0)">{footerLang[langs].Company} </a>
              </h4>
              <li>
                <Link to="/about_gsl" className="drop-inner">
                  {footerLang[langs].About}
                </Link>
              </li>
              <li>
                <Link to="/career" className="drop-inner">
                  {footerLang[langs].Jobs}
                </Link>
              </li>
              <li>
                <Link to="/company_history" className="drop-inner">
                  {footerLang[langs].CompanyHistory}
                </Link>
              </li>
            </ul>
          </div>
          <div class="col-xl-2 col-lg-2 col-sm-12 col-md-4 ">
            <ul class="footer-list-quk">
              <h4>
                <a href="javascript:void(0)">{footerLang[langs].Contactus}</a>
              </h4>
              <li>
                <Link to="/enquiry" className="drop-inner">
                  {footerLang[langs].Enquiry}
                </Link>
              </li>
              <li>
                <Link to="/faq" className="drop-inner">
                  {footerLang[langs].Faq}
                </Link>
              </li>
            </ul>
          </div>
          <div class="col-xl-3 col-lg-3 col-sm-12 col-md-6 ">
            <ul class="footer-list-quk others-links">
              <h4>
                <a href="javascript:void(0)">  {footerLang[langs].Policy}</a>
              </h4>

              <li>
                <Link to="/accessibility" className="drop-inner">
                  {footerLang[langs].Accessibility}
                </Link>
              </li>
              <li>
                <Link to="/data-protect-policy" className="drop-inner">
                  {footerLang[langs].DataProtectionPolicy}
                </Link>
              </li>
              <li>
                <Link
                  to="/gsl-cookies-and-tracking-policy"
                  className="drop-inner"
                >
                  {footerLang[langs].GSLCookiesandTrackingPolicy}
                </Link>
              </li>
              <li>
                <Link to="/terms_of_use" className="drop-inner">
                  {footerLang[langs].TermsofUse}
                </Link>
              </li>
            </ul>
          </div>

          <div class="col-xl-3 col-lg-3 col-sm-12 col-md-6 col-12 p-lg-0 p-xl-0">
            <form
              id="subscribe_email_form"
              onSubmit={handleSubmit(onSubmit)}
              name="subscribe_email_form"
            >
              <div class="footer-subs">
                <h4>{footerLang[langs].Subscribetoournewsletter}</h4>
                <input
                  type="email"
                  placeholder="Your email address"
                  name="email"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Enter your email-id",
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />

                <input
                  type="hidden"
                  name="footer_captcha"
                  id="footer_captcha"
                />
                <button id="subscribe_email_bt" ref={buttonRef}>
                  {" "}
                  {SubmitButton}
                </button>
              </div>
              {errors.email && (
                <span class="errors">{errors.email.message}</span>
              )}
              <div className="mt-3">
              <HCaptcha
                  sitekey="a8400b17-0f2c-4116-85cb-91a16f434d49"
                  onVerify={setToken}
                  ref={captchaRef}
                  apihost="https://cn1.hcaptcha.com"
                />
                {errors.hcpactha && (
                    <span class="errors">{errors.message.hcpactha}</span>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="copy-right">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-6 ">
              <p class="foot-cop-ang text-white">
                © Gold Star Line 2022. All rights reserved
              </p>
            </div>
            <div class="col-sm-12 col-md-6">
              <ul class="footer-social">
                <li>
                  <a
                    href="https://www.facebook.com/GoldStarLineLTD/"
                    target="_new"
                  >
                    <img src={facebook} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/gold-star-line-limited"
                    target="_new"
                  >
                    <img src={linked_ic} />
                  </a>
                </li>
                <li>
                <Link to="/social/wechat-qr">
                
                    <img class="wechat" src={wechat} />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div id="container_model" className={popHide ? "fadeOut" : "fadeIn"}>
        <div class="container py-4 px-4">
          <div class="row mb-3">
            <div class="col-xl-4">
              <input className="sinput" placeholder="From" />
            </div>
            <div class="col-xl-4">
              <input className="sinput" placeholder="From" />
            </div>
            <div class="col-xl-4">
              <ul class="s-radio-btns list-inline ">
                <li className="list-inline-item">
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      id="optradio"
                      value="Dep"
                      name="optradio"
                      class="custom-control-input"
                      checked="checked"
                    />
                    <label class="custom-control-label slabel" for="optradio">
                      Departure
                    </label>
                  </div>
                </li>
                <li className="list-inline-item">
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      id="optradio1"
                      value="Arv"
                      name="optradio"
                      class="custom-control-input"
                    />
                    <label class="custom-control-label slabel" for="optradio1">
                      Arrival
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4">
              <select className="sinput">
                <option>Week Ahead</option>
              </select>
            </div>
            <div class="col-xl-4">
              <input className="sinput" placeholder="From" />
            </div>
            <div class="col-xl-4">
              <input className="themebtn" type="submit" value="Submit" />
            </div>
          </div>
        </div>
      </div>

      <div
        className={"bottom-arrow " + (fixedBottomBar ? "" : "open")}
      >
        <div class="arrow">
          <a href="javascript:void(0);" onClick={() => showFixedBar()}>
            <div
              style={{ backgroundImage: 'url(' + (fixedBottomBar?footerDownArrowNew:footerUpArrowNew) + ')', backgroundSize: 'contain',width:'100%',height:'100%' }}
            ></div>
          </a>
        </div>
      </div>
      <div
        class="bottom-fixed-bar-search w-100"
        style={fixedBottomBar ? { display: "block" } : { display: "none" }}
      >
        <div className="container-fluid">
          {
            <div className="row fott-bottom">
              <div className="offset-md-1 col-xl-4 col-lg-4 col-md-4 footer-bar-tracker-box">
                <form
                  id="tracker_footer_form"
                  onSubmit={handleSubmit1(onSubmit_container)}
                >
                  <div class="tracker_footer_form_container d-flex">
                    <input
                      type="text"
                      placeholder="Container No. Or Bill of Lading No."
                      name="containerid"
                      id="footer_tracker_box"
                      style={{ textTransform: `uppercase` }}
                      required=""
                      {...register1("containerid", {
                        validate: (value) => {
                          return checkcontainer(value);
                        },
                        required: {
                          value: true,
                          message: "This field is required.",
                        },
                      })}
                    />
                    <button type="submit" id="tracker_footer_box_button">
                    {footerLang[langs].TrackShipment}  
                    </button>

                  </div>
                  {errors1.containerid && (
                    <span class="errors">{errors1.containerid.message}</span>
                  )}
                </form>
              </div>
              <div className="offset-md-1 col-xl-5 col-lg-5 col-md-5 footer-bar-button-box">
                <ul class="list-inline d-flex m-0">
                  <li className="list-inline-item">
                    {" "}
                    <Link to="/schedule_by_line">
                      <img
                        src={
                          footerHoverIconIndex == 2
                            ? footer_schedule_white
                            : footer_schedule_black
                        }
                      />{" "}
                      <span>{footerLang[langs].Schedule}</span>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    {" "}
                    <Link to="/point_to_point">
                      <img
                        src={
                          footerHoverIconIndex == 4
                            ? footer_track_white
                            : footer_track_black
                        }
                      />{" "}
                      <span>{footerLang[langs].FindRoute} </span>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    {" "}
                    <Link to="/request_a_quote">
                      <img
                        src={
                          footerHoverIconIndex == 0
                            ? quote
                            : quote
                        }
                      />{" "}
                      <span>{footerLang[langs].GetQuote}  </span>
                    </Link>
                  </li>

                  <li className="list-inline-item">
                    {" "}
                    <Link to="/tare_weight_inquiry">
                      <img
                        src={
                          footerHoverIconIndex == 1
                            ? footer_tar_white
                            : footer_tar_black
                        }
                      />{" "}
                      <span>{footerLang[langs].TareWeight}</span>
                    </Link>
                  </li>
                  <li className="list-inline-item gslgo">
                    {" "}
                    <Link to="/landing_gslgo">
                      <img
                        src={
                          footerHoverIconIndex == 0
                            ? gsl_go_icon
                            : gsl_go_icon
                        }
                      />{" "}
                      <span>{footerLang[langs].Login} </span>
                    </Link>
                  </li>


                </ul>
              </div>
            </div>
          }
        </div>
      </div>

      {/* <div class="cookie-bg">
  <div class="cookie-text">
    <h6>Accept Cookies<CloseButton/></h6>
        <p>We may use technologies, such as cookies or tracking software to enable the use of our Web Site or Service to remember your login details and preferences, obtain statistics to tailor our Service</p>
    </div>
              <div class="buttons-cont">
                <a href="#" class="btn btn-lg cookie-btn cokie-yel" data-dismiss="modal">I accept</a>
                <a href="#" class="btn btn-secondary btn-lg cookie-btn" data-dismiss="modal">Learn More</a>
            </div>  
    </div> */}
    </footer>
  );
};
export default Footer;
