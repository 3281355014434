const footerlang =
{
    "en": {
        "Service": "SERVICES",
        "PointToPoint": "Point To Point",
        "Schedule": "Schedule",
        "Equipment": "Equipment",
        "CargoServices": "Cargo Services",
        "Company": "COMPANY",
        "About": "About",
        "Jobs": "Jobs",
        "CompanyHistory": "Company History",
        "Contactus": "CONTACT US",
        "Enquiry": "Enquiry",
        "Faq": "FAQ",
        "Policy": "POLICY",
        "Accessibility": "Accessibility",
        "DataProtectionPolicy": "Data Protection Policy",
        "GSLCookiesandTrackingPolicy": "GSL Cookies and Tracking Policy",
        "TermsofUse": "Terms of Use",
        "CargoTracing": "Cargo Tracing",
        "GSLGo": "Login",
        "TareWeight": "Tare Weight",
        "Schedule": "Schedule",
        "PointtoPoint": "Find Route",
        "Subscribetoournewsletter": "Subscribe to our newsletter",
        "GO": "Go!",
        "TrackShipment": "Track Shipment",
        "FindRoute": "Find Route",
        "GetQuote": "Get Quote",
        "Login": "Login",
       
    },
    "cn": {
        "Service": "服務",
        "PointToPoint": "港口到港口",
        "Schedule": "船期表",
        "Equipment": "貨櫃",
        "CargoServices": "貨物托運服務",
        "Company": "公司",
        "About": "關於",
        "Jobs": "招聘",
        "CompanyHistory": "公司回顧",
        "Contactus": "聯絡我們",
        "Enquiry": "查詢",
        "Faq": "常見問題",
        "Policy": "政策",
        "Accessibility": "網頁協助",
        "DataProtectionPolicy": "數據保護政策",
        "GSLCookiesandTrackingPolicy": "GSL 網頁數據記錄政策",
        "TermsofUse": "使用條款",
        "CargoTracing": "貨物追蹤",
        "GSLGo": "GSL Go",
        "TareWeight": "空櫃重量",
        "PointtoPoint": "港口到港口",
        "Subscribetoournewsletter": "訂閲我們最新消息",
        "GO": "訂閲!",
        "TrackShipment": "追蹤貨物",
        "FindRoute": "查詢航線",
        "GetQuote": "獲取報價",
        "Login": "登入",
    },
    "zh": {
        "Service": "服务",
        "PointToPoint": "港口到港口",
        "Schedule": "船期表",
        "Equipment": "集装箱",
        "CargoServices": "货物托运服务",
        "Company": "公司",
        "About": "关于",
        "Jobs": "招聘",
        "CompanyHistory": "公司回顾",
        "Contactus": "联络我们",
        "Enquiry": "查询",
        "Faq": "常见问题",
        "Policy": "政策",
        "Accessibility": "网页协助",
        "DataProtectionPolicy": "数据保护政策",
        "GSLCookiesandTrackingPolicy": "GSL 网页数据记录政策",
        "TermsofUse": "使用条款",
        "CargoTracing": "货物追踪",
        "GSLGo": "GSL Go",
        "TareWeight": "空箱重量",
        "PointtoPoint": "港口到港口",
        "Subscribetoournewsletter": "订阅我们最新消息",
        "GO": "订阅",
        "TrackShipment": "追踪货物",
        "FindRoute": "查询航线",
        "GetQuote": "取得报价",
        "Login": "登录",
    },

    "ko": {
        "Service": "서비스",
        "PointToPoint": "항구별 검색",
        "Schedule": "스케줄",
        "Equipment": "컨테이너 장비",
        "CargoServices": "화물 서비스",
        "Company": "회사",
        "About": "회사 소개",
        "Jobs": "업무",
        "CompanyHistory": "회사 연혁",
        "Contactus": "연락처",
        "Enquiry": "문의",
        "Faq": "자주 묻는 질문",
        "Policy": "정책",
        "Accessibility": "접근성",
        "DataProtectionPolicy": "데이터 보호 정책",
        "GSLCookiesandTrackingPolicy": "GSL 개인정보 처리 방침",
        "TermsofUse": "사용 조건",
        "CargoTracing": "화물 추적",
        "GSLGo": "GSL Go",
        "TareWeight": "용기(컨테이너) 무게 ",
        "PointtoPoint": "항구별 검색",
        "Subscribetoournewsletter": "뉴스레터 구독",
        "GO": "Go",
        "TrackShipment": "화물 추척",
        "FindRoute": "경로 찾기",
        "GetQuote": "운임 받기",
        "Login": "로그인",
    },
    "vi": {
        "Service": "NHỮNG DỊCH VỤ",
        "PointToPoint": "Điểm đầu đến Điểm cuối",
        "Schedule": "Lịch tàu",
        "Equipment": "Thiết bị",
        "CargoServices": "Những dịch vụ hàng hóa",
        "Company": "CÔNG TY",
        "About": "Về",
        "Jobs": "Nghề nghiệp",
        "CompanyHistory": "Lịch sử công ty",
        "Contactus": "Liên hệ với chúng tôi",
        "Enquiry": "Yêu cầu",
        "Faq": "Câu hỏi thường gặp",
        "Policy": "Chính sách",
        "Accessibility": "Truy cập",
        "DataProtectionPolicy": "Chính sách bảo vệ dữ liệu",
        "GSLCookiesandTrackingPolicy": "Chính sách của GSL về Cookie và truy vết dữ liệu duyệt web",
        "TermsofUse": "Điều khoản sử dụng",
        "CargoTracing": "Theo dõi hàng hóa",
        "GSLGo": "GSL Go",
        "TareWeight": "Trọng lượng vỏ",
        "PointtoPoint": "Điểm đầu đến Điểm cuối",
        "Subscribetoournewsletter": "Đăng ký nhận thư tin tức",
        "GO": "LÊN ĐƯỜNG",
        "TrackShipment": "Tra cứu lô hàng",
        "FindRoute": "Tìm lịch tàu",
        "GetQuote": "Lấy báo giá",
        "Login": "Đăng nhập",
    },

    "th": {
        "Service": "บริการ",
        "PointToPoint": "สถานที่ต้นทาง ถึง สถานที่ปลายทาง",
        "Schedule": "ตารางเรือ",
        "Equipment": "ตู้คอนเทนนเอร์",
        "CargoServices": "บริการขนส่งสินค้า",
        "Company": "ข้อมูลบริษัท",
        "About": "เกี่ยวกับเรา",
        "Jobs": "ร่วมงานกับเรา",
        "CompanyHistory": "ประวัติบริษัท",
        "Contactus": "ติดต่อเรา",
        "Enquiry": "สอบถามข้อมูล",
        "Faq": "คำถามที่พบบ่อย",
        "Policy": "นโยบาย",
        "Accessibility": "การเข้าถึง",
        "DataProtectionPolicy": "นโยบายคุ้มครองข้อมูล",
        "GSLCookiesandTrackingPolicy": "นโยบายเกี่ยวกับคุ้กกี้และการติดตาม",
        "TermsofUse": "เงื่อนไขการใช้งาน",
        "CargoTracing": "ติดตามสินค้า",
        "GSLGo": "GSL Go",
        "TareWeight": "น้ำหนักตู้สินค้า",
        "PointtoPoint": "สถานที่ต้นทาง ถึง สถานที่ปลายทาง",
        "Subscribetoournewsletter": "สมัครสมาชิกเพื่อรับข่าวสาร",
        "GO": "GO",
        "TrackShipment": "ติดตามสถานะ",
        "FindRoute": "ค้นหาเส้นทาง",
        "GetQuote": "ขอรับใบเสนอราคา",
        "Login": "เข้าสู่ระบบ",
    },
    "in": {
        "Service": "Layanan",
        "PointToPoint": "Poin ke poin",
        "Schedule": "Jadwal",
        "Equipment": " Peralatan",
        "CargoServices": "Layanan Kargo",
        "Company": "Perusahaan",
        "About": "Tentang",
        "Jobs": "Pekerjaan",
        "CompanyHistory": "Sejarah Perusahaan",
        "Contactus": "Hubungi Kami",
        "Enquiry": "Pertanyaan",
        "Faq": "FAQ",
        "Policy": "Kebijakan",
        "Accessibility": "Aksesabilitas",
        "DataProtectionPolicy": "Kebijakan Proteksi Data",
        "GSLCookiesandTrackingPolicy": "Cookies dan Kebijakan Pelacakan GSL",
        "TermsofUse": "Syarat Penggunaan",
        "CargoTracing": "Pelacakan Kargo",
        "GSLGo": "GSL Go",
        "TareWeight": "Berat Tara",
        "PointtoPoint": "Poin ke poin",
        "Subscribetoournewsletter": "Berlangganan Surat Kabar Kami",
        "GO": "GO",
        "TrackShipment": "Lacak Pengiriman",
        "FindRoute": "Temukan Rute",
        "GetQuote": "Dapatkan Penawaran",
        "Login": "Login",
    },
    "ja": {
        "Service": "サービス",
        "PointToPoint": "ポイント・トゥ・ポイント",
        "Schedule": "スケジュール",
        "Equipment": "機器",
        "CargoServices": "貨物サービス",
        "Company": "会社",
        "About": "会社案内",
        "Jobs": "採用",
        "CompanyHistory": "会社沿革",
        "Contactus": "連絡先",
        "Enquiry": "照会",
        "Faq": "よくある質問",
        "Policy": "ポリシー",
        "Accessibility": "ユーザー補助機能",
        "DataProtectionPolicy": "データ保護ポリシー",
        "GSLCookiesandTrackingPolicy": "GSL Cookieおよびトラッキングポリシー",
        "TermsofUse": "利用規約",
        "CargoTracing": "貨物追跡",
        "GSLGo": "GSL Go",
        "TareWeight": "風袋重量",
        "PointtoPoint": "ポイント・トゥ・ポイント",
        "Subscribetoournewsletter": "ニュースレターを購読",
        "GO": "Go!",
        "TrackShipment": "貨物の追跡",
        "FindRoute": "ルート検索",
        "GetQuote": "お見積もり",
        "Login": "ログイン",
       
    },
}
export default footerlang;